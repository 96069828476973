/*header css*/

.top_row {
  background-color: #f5f5f5;
  color: #333;
  padding: 20px 35px;
}

.top_left {
  float: left;
  font-size: 14px;
  color: #333;
}

.top_right {
  float: right;
}

.top_search {
  float: left;
  margin: 0 20px 0 0;
}

.search_put {
  position: relative;
  display: block;
}

.search_put svg {
  position: absolute;
  left: 7px;
  top: 6px;
  font-size: 18px;
  color: #333;
}

.input_search {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px 5px 5px 30px;
  color: #6c6b6b;
  font-size: 14px;
}

.font_size {
  font-size: 16px;
  font-weight: 600;
  float: left;
}

.font_size a {
  color: #333;
  text-decoration: none;
}

.font_medium {
  font-size: 15px;
  margin: 0 10px;
}

.font_small {
  font-size: 14px;
}

.logo_area {
  padding: 15px 35px;
  background-color: #fff;
}

.institute_logo img {
  float: left;
  width: 100px;
  height: 100px;
  /* background-color: #e7ebf0; */
}

.institute_name {
  float: left;
  width: 70%;
  padding: 0 50px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 11px 0 0 0;
  color: #333;
}

.helpline {
  float: right;
  width: 21%;
  text-align: left;
}

.helpline_title {
  font-size: 18px;
  font-weight: 500;
  color: rgb(25, 118, 210);
  margin: 0;
}

.help_time {
  margin: 10px 0 0 0;
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.help_time svg {
  color: rgb(54, 140, 225);
  font-size: 20px;
  margin: 0px 3px 0 0;
  vertical-align: sub;
}

.navbar {
  background-color: rgb(25, 118, 210);
  display: block;
  padding: 0 35px;
}

.main_menu {
  float: left;
}

.main_menu ul {
  margin: 0px;
  padding: 0px;
}

.main_menu ul li {
  list-style-type: none;
  float: left;
}

.main_menu ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 15px;
  display: block;
  text-decoration: none;
}

.main_menu ul li a:hover,
.main_menu ul li a.active {
  background-color: rgb(54, 140, 225);
  color: #fff;
}

.login_area {
  float: right;
}

.login_r {
  display: block;
  margin: 0 10px 0 0;
  float: left;
}

.login_r a {
  background-color: rgb(54, 140, 225);
  color: #fff;
  padding: 12px 15px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.login_r a:hover {
  background-color: #fff;
  color: rgb(54, 140, 255);
}

.login_message {
  float: left;
  position: relative;
  color: #fff;
}

.login_message a {
  color: #fff;
  text-decoration: none;
}

.login_message a svg {
  font-size: 24px;
  margin: 12px 0 0 0;
}

.login_message a span {
  position: absolute;
  background-color: rgb(156, 39, 176);
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  padding: 2px 4px;
  top: 5px;
  left: 17px;
}

/**/
.marquee_bx {
  border: 1px solid #ccc;
  border-left: 5px solid rgb(156, 39, 176);
  margin: 15px 10px;
  padding: 5px;
}

.marquee_left {
  float: left;
  width: 10%;
  background-color: #b63ace;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 5px 15px;
}

.morquee_right {
  float: left;
  width: 90%;
  padding: 5px 0 0 10px;
  font-size: 14px;
}

.marquee_one {
  font-size: 14px;
  color: #333;
}

.marquee_one span {
  color: #999;
  font-size: 13px;
  margin: 0 5px 0 0;
}

.marquee_one span svg {
  font-size: 14px;
  margin: 4px 0 0 0;
  vertical-align: top;
  color: #999;
}

.programm_button {
  margin: 15px 0;
  padding: 0;
}

.ug_programm {
  margin: 0 10px;
}

.ug_programm a {
  background-color: #fff;
  border: 1px solid rgb(25, 118, 210);
  border-radius: 3px;
  padding: 10px 15px;
  color: rgb(25, 118, 210);
  text-decoration: none;
  margin: 0px 0 5px 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  display: block;
}

.ug_programm a:hover {
  background-color: rgb(35, 137, 238);
  color: #fff;
}

.title {
  text-align: center;
  font-size: 18px;
  color: #f00;
}

/* .event_pic{background-color: #E7EBF0; width: 100%; height: 168px; border-radius: 5px;
margin:0 0 10px 0;} */
.event_one {
  display: block;
}

.event_date {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 13px;
  color: rgb(83, 83, 83);
}

.event_l {
  float: left;
  width: 50%;
  margin: 0px;
  padding: 0;
  color: rgb(83, 83, 83);
}

.event_l svg {
  color: rgb(25, 118, 210);
  font-size: 14px;
}

.new_content {
  padding: 25px;
  margin: 0;
}

/*60px 0 0*/
.box_one {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0 10px;
}

.event_title {
  background-color: rgb(25, 118, 210);
  border-radius: 3px 3px 0 0;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 10px;
}

.event_title.purple {
  background-color: rgb(156, 39, 176);
}

.event_title.pink {
  background-color: rgb(233, 30, 99);
}

.event_cont {
  padding: 15px;
  text-align: left;
}

.event_nm {
  color: rgb(25, 118, 210);
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.event_sub_nm {
  color: #333;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px 0;
}

.event_sub_nm span {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  display: block;
  line-height: 22px;
}

.event_txt {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #ececec;
}

.event_more {
  background-color: #fff;
  border: 1px solid rgb(25, 118, 210);
  border-radius: 3px;
  padding: 6px 15px;
  color: rgb(25, 118, 210);
  text-decoration: none;
  margin: 0px 0 5px 0;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}

.event_more:hover {
  background-color: rgb(25, 118, 210);
  color: #fff;
}

.clr {
  clear: both;
}

.notification_one {
  position: relative;
  padding: 10px;
}

.notification_one {
  padding-bottom: 9px;
  margin-bottom: 0;
  border-bottom: 1px solid #ececec;
}

.noti_title {
  font-weight: 400;
  font-size: 13px;
  color: rgb(83, 83, 83);
  margin: 0px 0px 8px 0px;
  padding: 0;
}

.noti_title span {
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.noti_time {
  font-size: 13px;
  margin: 0;
  padding: 0;
  color: #999;
}

.more_noti {
  text-align: center;
  font-size: 15px;
}

.more_noti a {
  text-decoration: none;
  color: rgb(156, 39, 176);
  font-weight: 500;
}

.news_one {
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.news_cont {
  float: left;
  width: 70%;
  padding: 0 0 0 15px;
}

.news_pic {
  float: left;
  width: 30%;
  background-color: #e7ebf0;
  height: 100px;
}

.news_pic img {
  height: 100%;
  width: 100%;
}

.news_title {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 8px 0;
}

.news_time {
  color: #999;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.more_noti a.news {
  color: rgb(233, 30, 99);
}

.more_noti a.more {
  color: rgb(25, 118, 210);
}

.more_noti.padd_top {
  padding-top: 10px;
}

/*style={{margin:"60px 0 0 0"}}*/

/*FORM CSS START HERE*/
.form_input {
  margin: 0 10px 10px 10px;
  display: block;
  position: relative;
}

.form_input.form {
  margin: 0 10px 20px 10px;
}

.User_name {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 8px 0;
  color: #333;
}

.main_input {
  padding: 8px 14px;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  width: 100%;
  resize: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.error_msg {
  color: #f00;
  font-size: 13px;
  font-weight: 400;
  position: absolute;
}

.sec_title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  padding: 0 0 10px 0;
  margin: 0 0 15px 0;
  border-bottom: 1px solid #ccc;
}

/*responsive trable*/
/* .tableFixHead {
  overflow-y: auto;
  max-height: 55vh 500px;
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #ccc;
}
.tableFixHead thead th {
  position: sticky;
  top: 0px -1px;
  z-index: 99;
}
.tableFixHead table thead tr th {
  border: 1px solid #ccc;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 6px 16px;
  border: 1px solid lightgray;
  font-size: 15px;
}
.tableFixHead table tbody tr:nth-child(even) {
  background-color: #f6f6f6;
}
th {
  background: #0072e5 !important;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid #ccc;
} */

.buttons_row {
  display: inline-block;
  width: 100%;
  margin: 0 0 20px 0;
}

.all_buttons {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.primary_button {
  margin: 0 5px;
  background-color: #0072e5;
  color: #fff;
  border-radius: 3px;
  padding: 10px 18px;
  text-decoration: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.primary_button:hover {
  background-color: #1f89f4;
  color: #fff;
  cursor: pointer;
}

.primary_border {
  margin: 0 5px;
  background-color: #fff;
  color: #0072e5;
  border-radius: 3px;
  border: 1px solid #0072e5;
  padding: 9px 18px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.primary_border:hover {
  background-color: #0072e5;
  color: #fff;
  cursor: pointer;
}

.disable_button {
  margin: 0 5px;
  background-color: rgba(0, 0, 0, 0.12);
  padding: 10px 18px;
  color: rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

.success_button {
  margin: 0 5px;
  background-color: rgb(46, 125, 50);
  color: #fff;
  border-radius: 3px;
  padding: 10px 18px;
  text-decoration: none;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.success_button:hover {
  background-color: rgb(27, 94, 32);
  color: #fff;
  cursor: pointer;
}

.success_border {
  margin: 0 5px;
  background-color: #fff;
  color: rgb(46, 125, 50);
  border-radius: 3px;
  border: 1px solid rgb(46, 125, 50);
  padding: 9px 18px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.success_border:hover {
  background-color: rgb(46, 125, 50);
  color: #fff;
  cursor: pointer;
}

.error_button {
  margin: 0 5px;
  background-color: rgb(211, 47, 47);
  color: #fff;
  border-radius: 3px;
  padding: 10px 18px;
  text-decoration: none;
  border: 1px solid rgb(211, 47, 47);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.error_button:hover {
  background-color: rgb(238, 59, 59);
  color: #fff;
  cursor: pointer;
}

.error_border {
  margin: 0 5px;
  background-color: #fff;
  color: rgb(211, 47, 47);
  border-radius: 3px;
  border: 1px solid rgb(211, 47, 47);
  padding: 9px 18px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.error_border:hover {
  background-color: rgb(211, 47, 47);
  color: #fff;
  cursor: pointer;
}

.info-msg,
.success-msg,
.warning-msg,
.error-msg {
  margin: 10px 0;
  padding: 10px;
  border-radius: 3px 3px 3px 3px;
  font-size: 15px;
}

.info-msg svg,
.success-msg svg,
.warning-msg svg,
.error-msg svg {
  vertical-align: bottom;
}

.info-msg {
  color: #059;
  background-color: #bef;
}

.success-msg {
  color: #270;
  background-color: #dff2bf;
}

.warning-msg {
  color: #9f6000;
  background-color: #feefb3;
}

.error-msg {
  color: #d8000c;
  background-color: #ffbaba;
}

.checkbox_one {
  float: left;
  width: 25%;
}

.all_checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.all_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.all_checkbox:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.all_checkbox input:checked~.checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.all_checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.all_checkbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 7px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/*radio button css*/
.radio_one {
  float: left;
  width: 25%;
}

.all_radiobtn {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.all_radiobtn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio_checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 21px;
  width: 21px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.all_radiobtn:hover input~.radio_checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.all_radiobtn input:checked~.radio_checkmark {
  background-color: #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio_checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.all_radiobtn input:checked~.radio_checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.all_radiobtn .radio_checkmark:after {
  top: 6px;
  left: 6px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}

/*login page css start here*/
.lgn_bx {
  background-color: #f5f5f5;
  /*padding:15px;*/
  margin: 80px 0 20px 0;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.lgn_container {
  padding: 15px;
  display: block;
  background-color: #fff;
}

.lgn_title {
  background-color: #1f89f4;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  padding: 8px;
  color: #fff;
}

.user_lgn {
  display: block;
  width: 100%;
  position: relative;
  margin: 0 0 15px 0;
  text-align: left;
}

.user_label {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.user_label a {
  float: right;
}

.frgt_main {
  position: relative;
  margin: 10px 0;
}

.frgt_nm {
  text-align: right;
  font-size: 13px;
  font-weight: 500;
  float: right;
}

.frgt_nm.left {
  float: left;
  text-align: left;
}

.frgt_nm a {
  color: #333;
  text-decoration: none;
}

.frgt_nm a:hover {
  color: #0a72db;
}

.register_nm {
  display: inline-block;
  width: 100%;
  text-align: center;
  position: relative;
  margin: 10px 0 0 0;
  font-size: 14px;
}

.register_nm a {
  color: #333;
  font-weight: 500;
  text-decoration: underline;
  margin: 0 0 0 5px;
}

.register_nm a:hover {
  color: #0a72db;
  text-decoration: none;
}

.user_field {
  margin: 0 0 5px 0;
}

.lgn_btn {
  margin: 0;
  /*13px 0 0 0*/
  display: inline-block;
  width: 100%;
}

.user_lgn.mt {
  margin: 15px 0 0 0;
}

.register_btn {
  width: 100%;
  display: block;
  background-color: #1f89f4;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  padding: 10px;
  border-radius: 3px;
  border: none;
}

.register_btn:hover {
  background-color: #0a72db;
  cursor: pointer;
}

.admission_one {
  display: inline-block;
  width: 100%;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #ececec;
}

.admission_title {
  display: inline-block;
  width: 100%;
  margin: 0;
}

.admission_date {
  float: left;
  width: 25%;
  padding: 10px;
  text-align: center;
  background-color: #0a72db;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  color: #fff;
}

.admission_date span {
  display: block;
  font-size: 12px;
}

.admission_cont {
  float: left;
  width: 75%;
  padding: 0 0 0 15px;
}

.admission_txt {
  font-size: 14px;
  color: #333;
  margin: 0 0 10px 0;
  line-height: 24px;
}

.admission_apply {
  font-size: 13px;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #0a72db;
  color: #0a72db;
  text-decoration: none;
  padding: 6px 15px;
  border-radius: 3px;
  margin: 2px 0;
  display: inline-block;
}

.admission_apply:hover {
  background-color: #0a72db;
  color: #fff;
  border: 1px solid #0a72db;
}

.admission_detail {
  display: block;
  border-bottom: 1px solid #ccc;
  text-align: left;
  position: relative;
  padding: 15px 15px 15px 110px;
}

.admission_det_date {
  position: absolute;
  left: 0;
  top: 15px;
  background-color: #0a72db;
  color: #fff;
  padding: 25px 15px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
}

.admission_det_date span {
  display: block;
  font-size: 13px;
}

.admission_cont.detil {
  float: none;
  width: 100%;
  display: block;
  padding: 0px;
}

.news_detail {
  padding: 15px 15px 55px 200px;
  position: relative;
  text-align: left;
  display: block;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.news_detail_new {
  padding: 15px 15px 55px;
  position: relative;
  text-align: left;
  display: block;
  border-bottom: 1px solid #ccc;
  overflow: hidden;
}

.det_news_pic {
  position: absolute;
  left: 0px;
  top: 15px;
  background-color: transparent;
  height: auto;
  width: auto;
  padding: 0 0 15px 0;
}

.det_news_pic img {
  height: 170%;
  width: 100%;
}

.admission_detail.noti {
  display: block;
  border-bottom: 1px solid #ccc;
  text-align: left;
  position: relative;
  padding: 15px 15px 15px 15px;
}

.det_news_pic.pic {
  position: absolute;
  left: 0px;
  top: 15px;
  background-color: transparent;
  height: 100px;
  width: 180px;
  padding: 0 0 10px 0;
}

.admission_detail.admisdetail {
  display: block;
  border-bottom: 1px solid #ccc;
  text-align: left;
  position: relative;
  padding: 25px 15px 25px 120px;
}

admission_det_date.date {
  position: absolute;
  left: 0;
  top: 15px;
  background-color: #0a72db;
  color: #fff;
  padding: 25px 15px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 24px;
}

.register_btn.sendOtp {
  display: inline-block;
  width: auto;
  margin: 0 5px;
  font-size: 14px;
  padding: 10px 15px;
}

.user_lgn.fmlnm {
  display: inline-block;
  width: 33.33%;
  position: relative;
  margin: 0 0 25px 0;
  text-align: left;
  padding: 0 5px;
}

.user_lgn.genDob {
  display: inline-block;
  width: 50%;
  position: relative;
  margin: 0 0 25px 0;
  text-align: left;
  padding: 0 5px;
}

.new_content.login {
  border-top: 5px solid #cadff5;
  background-color: #f5f5f5;
  min-height: 80.5vh;
}

/* .new_login_content {
  width: 100% !important;

} */

.main_login_div {
  overflow: auto;
  width: 100% !important;
  height: 100vh !important;
  background: url("/src/Images/BGImg.jpg");
  object-fit: fill;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

}

.new_logo_content {
  width: 80px;
  height: 100px;
  background: url("/src/Images/logo3.png");
  object-fit: fill;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: row;
}

.new_title_content {
  color: #fff;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline;
}

.new_login_box1 {
  width: 1440px;
  height: 1024px;
}

.new_login_box3 {
  display: inline-flex;
  align-items: flex-start;
  position: absolute;
  vertical-align: middle;
  right: 5%;
  top: 10%;
  border-radius: 12px;
  background-color:rgba(255, 255, 255, 0.4);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 5px 10px 8x 10px #ffffff;
  
  
}

.new_login_logo {
  width: 736px;
  height: 719px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid rgba(0, 73, 105, 0.2);
  background: url("/src/Images/logo.jpg"),
    lightgray 14.151px 100px / 96.155% 81.919% no-repeat;
}

.error_page {
  width: 736px;
  height: 719px;
  border-radius: 16px 0px 0px 16px;
  border: 1px solid rgba(0, 73, 105, 0.2);
  background: url("/src/Images/404.jpg"),
    lightgray 14.151px 100px / 96.155% 81.919% no-repeat;
}

.new_login_box {
  /* width: 100%;
  height: 100%; */
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px 30px 30px 30px ;
}

.new_login_welcome {
  color: #000;
  /* Title */
  font-family: Poppins;
  font-size: 34px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  

}

.user_lgn_new {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.new_user_lable {
  color: var(--color-input-field-title, #1c1c1c);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new_user_feild {
  border-radius: 2px;
  border: 1px solid var(--primary-color, #004969);
  background: #fff;
  max-width: 360px;
  min-width: 240px;
  height: 43px;
  flex-shrink: 0;
}

.new_remember_me {
  color: var(--text-color, #1b1b1b);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new_login_btn {
  display: flex;
  width: 360px;
  height: 44px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--primary-color, #004969);
  background: var(--primary-color, #004969);
  cursor:pointer;
}

.new_login_btn:hover {
 
  border: 1px solid var(--primary-color, #004969);
  background: var(--primary-color, #002a3b);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor:pointer;
}


.outline-btn{
  display: flex;
  width: 360px;
  height: 44px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--primary-color, #004969);
  background: var(--primary-color, #ffffff);
  color: var(--primary-color, #004969);
  cursor:pointer;
}


.outline-btn:hover{
  display: flex;
  width: 360px;
  height: 44px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid var(--primary-color, #004969);
  background: var(--primary-color, #e9e9e9);
  color: var(--primary-color, #004969);;
  cursor:pointer;

}






.new_forgot_pswd {
  color: var(--primary-color, #004969);
  /* button text poppins */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.main_footer {
  display: inline-flex;
  align-items: center;
  gap: 2px;
}

.new_footer {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin: 20%; */
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.new_footer_logo {
  width: 49px;
  height: 34px;
  background-size: 100% !important;
  background-repeat: no-repeat;
  /* object-fit: contain; */
  background: url("/src/Images/logo1.png");

}

.new_footer_link {
  color: var(--primary-color, #004969);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.userName {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 12px 20px 5px;
  /* width: 30%; */
  text-align: right;
}

.acadamicYearName {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 4px 29px 0px;
  /* width: 30%; */
  text-align: right;
}

.currentAcadamicYear {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 7px 2px;
  width: 300px;
  text-align: right;
}

.acadamicYear {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 7px 2px;
  width: 200px;
  text-align: right;
}

.currentAcadamicYear {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 7px 2px;
  width: 220px;
  text-align: right;
}

.prnSearch {
  font-size: 14px;
  color: #fff;
  float: left;
  margin: 7px 2px 2px 2px;
  width: 300px;
  text-align: right;
}

.login_r a.easy_btn {
  float: left;
  margin: 7px 15px 7px 10px;
  background-color: rgb(54, 140, 225);
  color: #fff;
  padding: 4px 15px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

#basic-menu1 {
  float: left;
  margin: 0 0 0 10px;
}

.welcomelabel {
  font-size: 18px;
  color: #fff;
  float: left;
  margin: 12px 0;
}

/* .space {
  margin-left: 1px;
} */