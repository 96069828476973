.loading-shading-mui {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
    background: rgba(255, 255, 255, 0.26);
  }
  
  .loading-icon-mui {
    position: absolute;
    color:grey; 
    font-size: 60px;
    z-index: 9999;
    top: calc(40% - 30px);
    left: calc(50% - 30px);
  }
  